import NcImage from "components/NcImage/NcImage";
import NextPrev from "components/NextPrev/NextPrev";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import { PostDataType } from "data/types";
import { FC } from "react";
import Link from "components/Link";
import { handleHit } from "../../utils/handleHit";

export interface CardLarge1Props {
    className?: string;
    post: PostDataType;
    activeIndex?: number,
    totalCount?: number,
    onClickNext?: () => void;
    onClickPrev?: () => void;
}

const CardLarge1: FC<CardLarge1Props> = ({
    className = "",
    post,
    activeIndex,
    totalCount,
    onClickNext = () => {
    },
    onClickPrev = () => {
    },
}) => {
    const { featuredImage, title, href, commentCount, viewdCount, kod } = post;

    return (
        <div
            onClick={() => handleHit(kod)}
            className={`nc-CardLarge1 nc-CardLarge1--hasAnimation relative flex flex-col-reverse md:flex-row justify-end ${className}`}
        >
            <div
                className="md:absolute z-10 md:left-0 md:top-1/2 md:-translate-y-1/2 w-full md:mt-0 px-3 sm:px-6 md:px-0 md:w-3/5 lg:w-1/2 xl:w-2/5">
                <div
                    className="nc-CardLarge1__left p-4 sm:p-8 xl:py-14 md:px-10 bg-white/40 backdrop-filter backdrop-blur-lg shadow-lg dark:shadow-2xl rounded-3xl space-y-3 sm:space-y-5 ">

                    <h2 className="nc-card-title text-base sm:text-xl lg:text-2xl font-semibold ">
                        <Link href={href} className="line-clamp-2" title={title}>
                            {title}
                        </Link>
                    </h2>

                    <div className="flex items-center justify-between mt-auto">
                        <PostCardLikeAndComment href={href} likeCount={viewdCount} commentCount={commentCount} />
                    </div>
                </div>
                <div className="p-4 sm:pt-8 sm:px-10 flex items-center justify-around">
                    <NextPrev
                        btnClassName="w-11 h-11 text-xl"
                        onClickNext={onClickNext}
                        onClickPrev={onClickPrev}
                    />
                    <span>
                        {activeIndex} / {totalCount}
                    </span>
                </div>
            </div>
            <div className="w-full md:w-4/5 lg:w-2/3">
                <Link href={href} className="nc-CardLarge1__right block relative">
                    <NcImage
                        containerClassName="aspect-w-16 aspect-h-9 sm:aspect-h-9 md:aspect-h-14 lg:aspect-h-10 2xl:aspect-h-9 relative"
                        className="absolute inset-0 h-auto max-w-full rounded-3xl"
                        src={featuredImage}
                        alt={title}
                    // fill
                    // sizes="(max-width: 768px) 100vw, 50vw"
                    />
                </Link>
            </div>
        </div>
    );
};

export default CardLarge1;

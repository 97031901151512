import React, { useState } from 'react';
import { BiCookie } from "react-icons/bi";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

const Cookie = () => {
    const [accepted, setAccepted] = useState(false);
    const [language, setLanguage] = useState('de'); // Default olarak Almanca

    const acceptCookie = () => {
        setAccepted(true);
        localStorage.setItem('cookieAccepted', 'true');
    };

    if (localStorage.getItem('cookieAccepted')) {
        return null;
    }

    const containerVariants = {
        hidden: { y: '100%', opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 0.5, ease: 'easeOut' }
        },
        exit: { y: '100%', opacity: 0, transition: { duration: 0.5, ease: 'easeOut' } }
    };

    const textContent = {
        de: {
            message: "Unsere Website verwendet Cookies, um Ihnen das bestmögliche Erlebnis zu bieten und zur Reichweitenmessung. Durch die Nutzung unserer Seite erklären Sie sich damit einverstanden.",
            button: "Kabul Et",
            linkText: "Erfahren Sie mehr."
        },
        tr: {
            message: "Web sitemiz size mümkün olan en iyi deneyimi sunmak ve ölçüme ulaşmak için çerezler kullanmaktadır. Bu çerezlerin kullanılması için onayınız gerekmektedir.",
            button: "Kabul Et",
            linkText: "Daha fazla bilgi için tıklayınız."
        }
    };

    return (
        <AnimatePresence>
            {!accepted && (
                <motion.div
                    className="fixed bottom-5 left-2 right-2 md:left-0 md:right-0 w-full 2xl:w-1/2 h-auto md:h-36 mx-auto rounded-xl bg-white text-zinc-500 p-4 shadow-lg z-[999]"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <div className="flex flex-col md:flex-row items-center justify-between">
                        <div className="flex items-center w-full md:w-auto">
                            <BiCookie className="text-red-600 mr-2" size={80} />
                            <span className="text-sm md:text-base">
                                {textContent[language].message}
                                <br />
                                <Link to="/cerez-politikasi" className="underline text-brown-700 font-bold">
                                    {textContent[language].linkText}
                                </Link>
                            </span>
                        </div>
                        <button
                            onClick={acceptCookie}
                            className="bg-red-600 hover:bg-red-600 text-white font-bold w-36 h-10 rounded-full focus:outline-none focus:shadow-outline transition duration-300 mt-4 md:mt-0"
                        >
                            {textContent[language].button}
                        </button>
                    </div>
                    <div className="flex justify-center mt-2">
                        <button
                            onClick={() => setLanguage('de')}
                            className={`mx-2 ${language === 'de' ? 'font-bold' : ''}`}
                        >
                            Deutsch
                        </button>
                        <button
                            onClick={() => setLanguage('tr')}
                            className={`mx-2 ${language === 'tr' ? 'font-bold' : ''}`}
                        >
                            Türkçe
                        </button>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default Cookie;

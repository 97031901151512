import DBRoutes from "./routers";
import useHttpClient from "./hooks/useHttpClient";
import useFormData from "./hooks/useFormData";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "./stores/auth";
import { getCookie, setCookie } from "./hooks/cookieHooks";
import { fetchAPI } from "./hooks/fetchApı";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Loading from "./components/Loading";
import { setSettingsRed } from "./stores/settings";
import { BiLogoWhatsapp } from "react-icons/bi";

function App() {
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState([]);

    const { sendRequest } = useHttpClient();
    const createFormData = useFormData();

    const dispatch = useDispatch();

    const cookieToken = getCookie('t');

    const token = useSelector(state => state.auth) || cookieToken;

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                if (!token) {
                    const formData = new FormData();

                    const pass = btoa("*-api.1453?User2024*-");

                    formData.append("action", "avrupaninsesi");
                    formData.append("page", "login");
                    formData.append("username", "api_user");
                    formData.append("password", pass);
                    const response = await axios.post('https://demo.avrupaninsesi.com/api/index.php', formData);
                    const { error, message } = response.data;
                    if (error) {
                        alert(message);
                    } else {
                        const { token } = response.data.data;
                        dispatch(setToken(token));
                        setCookie('t', token, '/');
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
            try {
                const settingData = await fetchAPI('settings', createFormData, sendRequest);
                setSettings(settingData[0]);
                dispatch(setSettingsRed(settingData[0]));
            } catch (error) {
                console.error("Error fetching settings:", error);
            }
        }

        fetchData();
    }, [createFormData, sendRequest, dispatch, token]);
    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <HelmetProvider>
                    {settings && (
                        <Helmet>
                            <link rel="icon" href="/logo-small.png" />
                            <title>{settings.site_title}</title>
                            <meta name="description" content={settings.aciklama} />
                            <meta name="keywords" content={settings.kelime} />
                            <meta itemProp="publisher" content={settings.aciklama} />
                            <meta name="robots" content="FOLLOW, INDEX" />
                            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                            <meta name="msapplication-starturl" content={settings.web} />
                            <meta name="twitter:card" content="summary_large_image" />
                            <meta property="og:url" content={settings.web} />
                            <meta property="og:title" content={settings.site_title} />
                            <meta property="og:description" content={settings.aciklama} />
                            <meta name="msapplication-window" content="width=1024;height=768" />
                            <base url={settings.web} />
                            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                            <meta name="google-site-verification"
                                content="J-1q-br2q8zhlFY4XOAgI9h9jFDUY_HfEOPpiGMC98w" />
                            <link rel="canonical" href={settings.web} />
                            <meta name="viewport" content="width=device-width, initial-scale=1" />
                            <meta name="theme-color" content="#000000" />
                            <link rel="manifest" href="manifest.json" />
                            <link rel="alternate" type="application/rss+xml" title="AVRUPA'NIN SESİ"
                                href="https://avrupaninsesi.com/sitemap.xml" />
                            <link rel="alternate" type="application/rss+xml" title="AVRUPA'NIN SESİ"
                                href="https://avrupaninsesi.com/news_sitemap.xml" />
                        </Helmet>
                    )}
                    <div
                        className="bg-[#f8f8f8] text-base dark:bg-neutral-900/95 text-neutral-900 dark:text-neutral-200 font-body">
                        <DBRoutes />

                        <a href="whatsapp://send?phone=+491746407017">
                            <img src="/blue.png" style={{ filter: ' hue-rotate(900deg)' }} className="fixed -bottom-1 -right-20 z-[50] w-[205px]" alt="" />
                            <BiLogoWhatsapp className="fixed bottom-3 right-0 z-[51] text-white cursor-pointer"
                                size={64} />
                        </a>
                    </div>
                </HelmetProvider>
            )}
        </>
    );
}

export default App;

import React, { FC } from "react";
import { PostDataType } from "data/types";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import Link from "components/Link";
import Image from "components/Image";
import { handleHit } from "../../utils/handleHit";

export interface Card2Props {
    className?: string;
    post: PostDataType;
    size?: "normal" | "large";
}

const Card2: FC<Card2Props> = ({
    className = "h-full",
    size = "normal",
    post,
}) => {
    const { title, href, featuredImage, postType, viewdCount, commentCount, kod } =
        post;

    return (
        <div onClick={() => handleHit(kod)} className={`nc-Card2 group relative flex flex-col justify-center items-center ${className}`}>
            <div className="">
                <Image
                    // fill
                    sizes="(max-width: 600px) 480px, 800px"
                    className="max-w-full h-auto rounded-3xl"
                    src={featuredImage}
                    alt={title}
                />
                <PostTypeFeaturedIcon
                    className="absolute bottom-2 left-2"
                    postType={postType}
                    wrapSize="w-8 h-8"
                    iconSize="w-4 h-4"
                />
            </div>

            <Link href={href} className="absolute inset-0" />

            <div className="mt-5 px-4 flex flex-col">
                <div className="space-y-3">
                    <h2
                        className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 ${size === "large" ? "text-base sm:text-lg md:text-xl" : "text-base"
                            }`}
                    >
                        <Link href={href} className="line-clamp-2" title={title}>
                            {title}
                        </Link>
                    </h2>
                </div>
                <div className="my-5 border-t border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex items-center justify-between">
                    <PostCardLikeAndComment likeCount={viewdCount} commentCount={commentCount} href={href}
                        className="relative" />
                </div>
            </div>
        </div>
    );
};

export default Card2;

import 'react-tooltip/dist/react-tooltip.css'

import { Tooltip } from 'react-tooltip'

import CategoryBadgeList from "../../components/CategoryBadgeList/CategoryBadgeList";
import SingleTitle from "../../app/(singles)/SingleTitle";
import { useParams } from "react-router-dom";
import Image from "../../components/Image";
import { useEffect, useRef, useState } from "react";
import PostCardLikeAction from "../../components/PostCardLikeAction/PostCardLikeAction";
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import Tag from "../../components/Tag/Tag";
import useHttpClient from "../../hooks/useHttpClient";
import useFormData from "../../hooks/useFormData";
import { fetchAPI } from "../../hooks/fetchApı";
import Loading from "../../components/Loading";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import Page404 from "../not-found";
import SectionSliderImages from "../../components/Sections/SectionSliderImages";
import { toast, Toaster } from "react-hot-toast";
import { FiShare2 } from "react-icons/fi";
import { FaFacebook, FaLinkedin, FaTelegram, FaXTwitter } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { BsCopy } from "react-icons/bs";
import Schema from "../../components/Schema";
import BackgroundSection from "../../components/BackgroundSection/BackgroundSection";
import SectionSliderPosts from "../../components/Sections/SectionSliderPosts";
import { formatDateTime } from "utils/formatDateTime";
import { TbClockEdit, TbClockPlus } from "react-icons/tb";
import SectionAds from 'components/Sections/SectionAds';
import GoBackButton from 'components/GoBackButton';

export default function Post() {
    const params = useParams();
    const endedAnchorRef = useRef(null);
    const contentRef = useRef(null);
    const progressRef = useRef(null);
    const dialogRef = useRef(null);

    const [ads, setAds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [details, setDetail] = useState("");
    const [gallery, setGallery] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [isShowScrollToTop, setIsShowScrollToTop] = useState(false);
    const [otherNews, setOtherNews] = useState([]);

    const { sendRequest } = useHttpClient();
    const createFormData = useFormData();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dialogRef.current && !dialogRef.current.contains(event.target)) {
                setOpenDialog(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dialogRef]);

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await fetchAPI('newsDetails', createFormData, sendRequest, null, null, params.slug);
                const detailsData = data.details;
                const galleryData = data.gallery;
                const others = data.otherNews;

                others.forEach(item => {
                    item.featuredImage = 'https://avrupaninsesi.com/isDosyalar/' + item.resim;
                    item.href = '/' + item.href;
                });
                setOtherNews(others);

                galleryData.forEach(item => {
                    item.featuredImage = 'https://avrupaninsesi.com/isDosyalar/' + item.yol + "/galeri_" + item.url;
                    item.src = 'https://avrupaninsesi.com/isDosyalar/' + item.yol + "/galeri_" + item.url;
                });

                setGallery(galleryData);

                const adsData = await fetchAPI('getAds', createFormData, sendRequest, null, null, 48);
                adsData.forEach(item => {
                    item.imgAds = 'https://avrupaninsesi.com/isDosyalar/' + item.yol + "/" + item.url;
                });
                setAds(adsData);

                const regex = /\/isDosyalar/g;
                detailsData.forEach(item => {
                    item.featuredImage = 'https://avrupaninsesi.com/isDosyalar/' + item.resim;
                    if (!item.detay.includes('https://avrupaninsesi.com/isDosyalar/')) {
                        item.detay = item.detay.replace(regex, "https://www.avrupaninsesi.com/isDosyalar");
                    }
                    if (item.detay.includes('https://www.youtube.com/watch?v=')) {
                        const youtubeUrl = item.detay.match(/(https:\/\/www.youtube.com\/watch\?v=([a-zA-Z0-9_-]+))\*\*\*/);
                        if (youtubeUrl && youtubeUrl.length >= 3) {
                            const videoId = youtubeUrl[2];
                            const embedCode = `<iframe class="w-full h-96 object-cover" src="https://www.youtube.com/embed/${videoId}" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
                            item.detay = item.detay.replace(youtubeUrl[1], embedCode).replace("***", '');
                        }
                    }
                    if (item.etiketler) item.etiketler = item.etiketler.replace(/,\s*$/, '');
                });
                setDetail(detailsData[0]);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        }

        fetchData();
    }, [createFormData, sendRequest, params.slug]);


    const endedAnchorEntry = useIntersectionObserver(endedAnchorRef, {
        threshold: 0,
        root: null,
        rootMargin: "0%",
        freezeOnceVisible: false,
    });


    useEffect(() => {
        const handleProgressIndicator = () => {
            const entryContent = contentRef.current;
            const progressBarContent = progressRef.current;

            if (!entryContent || !progressBarContent) {
                return;
            }

            const totalEntryH = entryContent.offsetTop + entryContent.offsetHeight;
            let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
            let scrolled = (winScroll / totalEntryH) * 100;

            progressBarContent.innerText = scrolled.toFixed(0) + "%";

            if (scrolled >= 100) {
                setIsShowScrollToTop(true);
            } else {
                setIsShowScrollToTop(false);
            }
        };

        const handleProgressIndicatorHeadeEvent = () => {
            window?.requestAnimationFrame(handleProgressIndicator);
        };
        handleProgressIndicator();
        window?.addEventListener("scroll", handleProgressIndicatorHeadeEvent);
        return () => {
            window?.removeEventListener("scroll", handleProgressIndicatorHeadeEvent);
        };
    }, []);

    const showLikeAndCommentSticky =
        !endedAnchorEntry?.intersectionRatio &&
        (endedAnchorEntry?.boundingClientRect.top || 0) >= 0;

    const sharedSocial = (socialMedia, text) => {
        const url = window.location.href;
        switch (socialMedia) {
            case "copy":
                const el = document.createElement('textarea');
                el.value = window.location.href;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                toast.success("Link kopyalandı")
                setOpenDialog(false)
                break;

            case "facebook":
                const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
                window.open(facebookURL, "_blank");
                break;

            case "twitter":
                const twitterURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;
                window.open(twitterURL, "_blank");
                break;

            case "whatsapp":
                const whatsappURL = `whatsapp://send?text=${encodeURIComponent(text + "\n" + url)}`;
                window.open(whatsappURL, "_blank");
                break;

            case "telegram":
                const telegramURL = `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
                window.open(telegramURL, "_blank");
                break;

            case "linkedin":
                const linkedinURL = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}&title=${encodeURIComponent(text)}`;
                window.open(linkedinURL, "_blank");
                break;

            default:
                break;
        }
        setOpenDialog(false);
    }
    const renderHeader = () => {
        return (
            <div className="nc-SingleHeader">
                <div className="space-y-5 dark text-neutral-100">
                    <CategoryBadgeList
                        className="flex flex-wrap space-x-2 justify-center"
                        itemClass="!px-3 text-center "
                        categories={[{
                            "name": details.category,
                            "href": `/kategori/${details.category_seo}`,
                            "color": "indigo",
                            "taxonomy": "category",
                        }]}
                    />
                    <SingleTitle
                        mainClass="text-neutral-900 font-semibold text-3xl md:!leading-[120%] dark:text-neutral-100 text-center"
                        title={details.title}
                    />

                    <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
                    <div className="flex flex-col space-y-5 items-center relative">
                        <div className="nc-SingleMetaAction2 ">
                            <div className="flex flex-row space-x-2.5 items-center">
                                {/* <PostCardLikeAndComment
                                    likeCount={details.hit}
                                    commentCount={totalComment}
                                    itemClass="px-4 h-9 text-sm"
                                    hiddenCommentOnMobile
                                    useOnSinglePage
                                    className="!space-x-2.5"
                                /> */}
                                <div
                                    onClick={() => setOpenDialog(!openDialog)}
                                    className="min-w-[68px] h-9 px-4 flex items-center justify-center gap-x-2.5 cursor-pointer text-sm rounded-full text-neutral-6000 bg-neutral-50 transition-colors dark:text-neutral-200 dark:bg-neutral-800 hover:bg-blue-50 dark:hover:bg-blue-100 hover:text-teal-600 dark:hover:text-blue-500">
                                    <FiShare2 size={18} />
                                    Paylaş
                                </div>
                                <div
                                    ref={dialogRef}
                                    className={`absolute bg-white text-zinc-500 rounded-2xl shadow-lg grid grid-cols-12 top-10 right-0 md:p-10 p-5 gap-y-3 z-50 ${openDialog ? 'block' : 'hidden'}`}>
                                    <p className="col-span-12 flex items-center gap-x-2.5 cursor-pointer hover:text-zinc-500 transition-colors"
                                        onClick={() => sharedSocial("copy", details.title)}>
                                        <BsCopy className="text-zinc-500" size={16} /> Linki Kopyala
                                    </p>

                                    <p className="col-span-12 flex items-center gap-x-2.5 cursor-pointer hover:text-blue-500 transition-colors"
                                        onClick={() => sharedSocial("facebook", details.title)}>
                                        <FaFacebook className="text-blue-500" size={16} /> Facebook'ta Paylaş
                                    </p>

                                    <p className="col-span-12 flex items-center gap-x-2.5 cursor-pointer hover:text-zinc-800 transition-colors"
                                        onClick={() => sharedSocial("twitter", details.title)}>
                                        <FaXTwitter className="text-zinc-800" size={16} /> Twitter'da Paylaş
                                    </p>

                                    <p className="col-span-12 flex items-center gap-x-2.5 cursor-pointer hover:text-green-500 transition-colors"
                                        onClick={() => sharedSocial("whatsapp", details.title)}>
                                        <FaWhatsapp className="text-green-500" size={16} /> Whatsapp'ta Paylaş
                                    </p>

                                    <p className="col-span-12 flex items-center gap-x-2.5 cursor-pointer hover:text-blue-400 transition-colors"
                                        onClick={() => sharedSocial("telegram", details.title)}>
                                        <FaTelegram className="text-blue-400" size={16} /> Telegram'da Paylaş
                                    </p>

                                    <p className="col-span-12 flex items-center gap-x-2.5 cursor-pointer hover:text-blue-700 transition-colors"
                                        onClick={() => sharedSocial("linkedin", details.title)}>
                                        <FaLinkedin className="text-blue-700" size={16} /> Linkedin'de Paylaş
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="flex justify-between gap-x-10">
                                <span
                                    data-tooltip-id="addedDate-tooltip"
                                    data-tooltip-content="Ekleme Tarihi"
                                    data-tooltip-place="bottom"
                                    className="flex items-center gap-x-1.5">
                                    <TbClockPlus className="inline" size={24} />
                                    <b>{formatDateTime(details.addedDate)}</b>
                                </span>

                                <span
                                    data-tooltip-id="editDate-tooltip"
                                    data-tooltip-content="Son Güncelleme Tarihi"
                                    data-tooltip-place="bottom"
                                    className="flex items-center gap-x-1.5">
                                    <TbClockEdit className="inline" size={24} />
                                    <b>{formatDateTime(details.editDate, "y/m/d h:i")}</b>
                                </span>


                                <Tooltip id="addedDate-tooltip" />
                                <Tooltip id="editDate-tooltip" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const renderImage = () => {
        return (
            <div
                className="aspect-w-16 aspect-h-9 sm:aspect-h-9 border-2 border-neutral-300 dark:border-neutral-800 shadow-2xl bg-neutral-800 lg:rounded-3xl mb-3 lg:mb-auto overflow-hidden mt-6">
                <div className="absolute inset-0 z-10 cursor-default">
                    <Image
                        fill={true}
                        src={details.featuredImage}
                        sizes="(max-width: 1024px) 100vw, 50vw"
                        alt="single"
                    />
                </div>
            </div>
        )
    }

    const pageCard = () => {
        return (
            <div
                className={`sticky mt-8 bottom-8 !z-50 justify-center ${showLikeAndCommentSticky ? "flex" : "hidden"
                    }`}
            >
                <div
                    className="bg-white dark:bg-neutral-800 shadow-lg rounded-full ring-1 ring-offset-1 ring-neutral-900/5 p-1.5 flex items-center justify-center space-x-2 text-xs">
                    <PostCardLikeAction likeCount={details.hit} className="px-3 h-9 text-xs" />
                    <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>
                    {/* <PostCardCommentBtn
                        commentCount={""}
                        isATagOnSingle
                        className={` flex px-3 h-9 text-xs`}
                    /> */}
                    <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>

                    <button
                        className={`w-9 h-9 items-center justify-center bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-100 rounded-full ${isShowScrollToTop ? "flex" : "hidden"
                            }`}
                        onClick={() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                    >
                        <ArrowUpIcon className="w-4 h-4" />
                    </button>

                    <button
                        ref={progressRef}
                        className={`w-9 h-9 items-center justify-center ${isShowScrollToTop ? "hidden" : "flex"
                            }`}
                        title="Go to top"
                    >
                        %
                    </button>
                </div>
            </div>
        )
    }

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                {etiketler.map((item, key) => (
                    <Tag hideCount key={key} tag={item} className="mr-2 mb-2" />
                ))}
            </div>
        )
    }

    const renderOtherNews = () => {
        return (
            <>
                <div className="relative py-8">
                    <BackgroundSection />
                    <SectionSliderPosts
                        posts={otherNews}
                    />
                </div>
            </>
        )
    }

    const randomAdIndex = Math.floor(Math.random() * ads.length);

    const renderContent = () => {
        return (
            <>
                <Toaster />
                {ads[randomAdIndex] && (
                    <div className="container">
                        <SectionAds link={ads[randomAdIndex].link} imgAds={ads[randomAdIndex].imgAds} title={ads[randomAdIndex].baslik} />
                    </div>
                )}

                <div className="relative">
                    <div className="nc-SingleContent space-y-10">
                        <div
                            id="single-entry-content"
                            className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
                            ref={contentRef}
                        >
                            {details.spot && (
                                <div className="my-5">
                                    <blockquote
                                        className="p-4 my-4 border-s-4 border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-800">
                                        <svg className="w-8 h-8 text-gray-400 dark:text-gray-600 mb-4" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
                                            <path
                                                d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                                        </svg>
                                        <p className="text-xl italic font-medium leading-relaxed text-gray-900 dark:text-white">{details.spot}</p>
                                    </blockquote>
                                </div>
                            )}

                            <FroalaEditorView
                                className="!z-10"
                                model={details.detay}
                            />
                        </div>
                        {renderTags()}

                        {gallery && gallery.length > 0 && (
                            <>
                                <div
                                    className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
                                <div id="gallery" className="scroll-mt-20 mx-auto pt-5 text-center">
                                    <div
                                        className="bg-neutral-900 text-neutral-100 p-12 rounded-lg">
                                        <SectionSliderImages
                                            posts={gallery}
                                        />
                                    </div>
                                </div>
                            </>
                        )}

                        {renderOtherNews()}

                        <div
                            className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
                    </div>
                    {pageCard()}
                </div>
            </>
        )
    }

    let etiketler = [];
    if (details.etiketler) {
        etiketler = details.etiketler.split(',')
            .map(item => item.trim())
            .filter(item => item !== '')
            .map(item => ({ name: item, href: `/etiket/${item.replace(/\s/g, '-').toLowerCase()}` }));
    }

    return (
        <>
            {loading && <Loading />}
            {!details && (
                <Page404 />
            )}
            {!loading && details && (
                <Schema content={details} gallery={gallery}>
                    <GoBackButton />

                    <header className="container relative lg:py-20 flex flex-col lg:flex-row lg:items-center">

                        <div
                            className=" absolute inset-y-0 transform translate-x-1/2 right-1/2 w-screen lg:translate-x-0 lg:w-[calc(100vw/2)] bg-neutral-900 dark:bg-black dark:bg-opacity-50 lg:rounded-b-[40px] md:rounded-b-none rounded-b-[40px] rounded-t-none"></div>
                        <div className="pb-10 lg:pb-0 lg:pr-10 relative">

                            {renderHeader()}
                        </div>

                        <div className="relative lg:w-8/12 flex-shrink-0  z-10 lg:order-2 -order-1">
                            {renderImage()}
                        </div>
                    </header>


                    <div className="container mt-10 max-w-7xl relative">
                        {renderContent()}
                    </div>
                </Schema >
            )
            }
        </>
    )
}